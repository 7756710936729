/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import classes from './CreateExpensePage.module.scss';
import {
	AccountLink,
	CreateExpense,
	Reimburse,
	ConfirmAddress,
	CreateExpenseSuccess,
	DocumentUpload
} from '../../components/CreateExpense';
import { Incomplete } from '../../components/icons';
import { Button, ButtonTypes } from '../../components/common/Button';
import Modal from 'react-modal';
import { useLocation, useNavigate } from 'react-router-dom';
import { StyledLink } from '../../components/common/Link';
import { StyledLinkTypes } from '../../components/common/Link/StyledLink.constants';
import { Helmet } from 'react-helmet';

type ExpenseType = {
	expenseFormData: Record<string, string | null>;
	expenseDetailsSuccess: Record<string, string | boolean>;
	attachments: Array<Record<string, any>>;
	evaluateData: any;
	reimburseData: {
		expense_draft_id: string;
		evaluation_type: string;
		reimburse_to: string;
		is_reimburse_now: boolean;
		reimburse_from: string;
	};
	reimburseFormData: {
		from: string;
		secondary_from: string;
		to: string;
		has_hsa: string;
	};
};

export const CreateExpensePage = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const [step, setStep] = useState<number>(1);

	const [expenseDetails, setExpense] = useState<ExpenseType>({
		expenseFormData: {},
		expenseDetailsSuccess: {},
		attachments: [],
		evaluateData: {},
		reimburseData: {
			expense_draft_id: '',
			evaluation_type: '',
			reimburse_to: '',
			reimburse_from: '',
			is_reimburse_now: false
		},
		reimburseFormData: {
			from: '',
			secondary_from: '',
			to: '',
			has_hsa: ''
		}
	});

	useEffect(() => {
		if (location.state?.expenseData) {
			setExpense(location.state?.expenseData);
			setStep(5);
		}
	}, [location.state?.expenseData]);

	const [cancelModal, setCancelModal] = useState<boolean>(false);

	const setExpenseDetails = (details: Record<string, string | null>) => {
		setExpense(previousExpense => {
			return { ...previousExpense, expenseFormData: details };
		});
	};

	const setExpenseDetailsSuccess = (details: Record<string, string | boolean>) => {
		setExpense(previousExpense => {
			return { ...previousExpense, expenseDetailsSuccess: details };
		});
	};

	const setAttachmentSuccess = (attachments: Array<Record<string, string | number>>) => {
		setExpense(previousExpense => {
			return { ...previousExpense, attachments: attachments };
		});
	};

	const setEvaluateData = (evaluateData: any) => {
		setExpense(previousExpense => {
			return { ...previousExpense, evaluateData };
		});
	};

	const setReimburseData = (reimburseData: any) => {
		setExpense(previousExpense => {
			return { ...previousExpense, reimburseData };
		});
	};

	const setReimburseFormData = (reimburseFormData: any) => {
		setExpense(previousExpense => {
			return { ...previousExpense, reimburseFormData };
		});
	};

	const previousStep = () => {
		if (step > 1) {
			window.scrollTo(0, 0);
			setStep(step - 1);
		}
	};

	const nextStep = (increaseBy?: number) => {
		window.scrollTo(0, 0);
		setStep(step + (increaseBy || 1));
	};

	const showStep = () => {
		let screen = <AccountLink nextStep={nextStep} />;
		switch (step) {
			case 1:
				screen = <AccountLink nextStep={nextStep} />;
				break;
			case 2:
				screen = (
					<CreateExpense
						onCancel={() => {
							setCancelModal(true);
						}}
						nextStep={nextStep}
						updateExpenseDetails={setExpenseDetails}
						expenseDetails={expenseDetails}
						updateExpenseDetailsSuccess={setExpenseDetailsSuccess}
					/>
				);
				break;
			case 3:
				screen = (
					<DocumentUpload
						nextStep={nextStep}
						previousStep={previousStep}
						expenseDetails={expenseDetails}
						updateAttachments={setAttachmentSuccess}
						updateEvaluateData={setEvaluateData}
						updateExpenseDetails={setExpenseDetails}
						updateExpenseDetailsSuccess={setExpenseDetailsSuccess}
					/>
				);
				break;
			case 4:
				screen = (
					<Reimburse
						previousStep={previousStep}
						nextStep={nextStep}
						expenseDetails={expenseDetails}
						setReimburseData={setReimburseData}
						updateEvaluateData={setEvaluateData}
						updateReimburseFormData={setReimburseFormData}
					/>
				);
				break;
			case 5:
				screen = (
					<ConfirmAddress
						previousStep={previousStep}
						nextStep={nextStep}
						expenseDetails={expenseDetails}
					/>
				);
				break;
			case 6:
				screen = <CreateExpenseSuccess expenseDetails={expenseDetails} />;
				break;
			default:
				screen = <AccountLink nextStep={nextStep} />;
		}

		return screen;
	};
	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			width: '100%',
			maxWidth: '560px',
			transform: 'translate(-50%, -50%)',
			padding: '56px 24px',
			backgroundColor: '#001b47',
			color: '#fff',
			borderColor: '#001b47'
		}
	};
	return (
		<>
			<Helmet>
				<title>Create Expense - InComm Benefits</title>
			</Helmet>
			<Modal
				isOpen={cancelModal}
				style={customStyles}
				onRequestClose={() => {
					setCancelModal(false);
				}}
				contentLabel="Cancel Create Expense"
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
				ariaHideApp={false}
			>
				<div className="d-flex flex-column align-items-center">
					<div
						onClick={() => {
							setCancelModal(false);
						}}
						className="modal-close-btn-container"
					>
						<Incomplete color={'#facc15'}></Incomplete>
					</div>
					<div className={` text-center py-16  text-h4-325 text-yellow`}>Confirm Cancel</div>

					<p className={`px-64 py-16 text-base-325`}>
						Your expense information will not be saved. You will need to start over and reenter your
						expense information. Do you wish to proceed?
					</p>

					<div className={`d-flex flex-row flex mt-32 gap-20`}>
						<Button
							type={ButtonTypes.TERTIARY}
							onClick={() => {
								navigate('/apps/expenses/summary');
							}}
						>
							Yes
						</Button>
						<Button
							onClick={() => {
								setCancelModal(false);
							}}
						>
							Close
						</Button>
					</div>
				</div>
			</Modal>
			<div className="d-flex justify-content-between align-items-center mt-96">
				<h1 className={classes['header-text']}>Create Expense</h1>
				{step < 6 && (
					<StyledLink
						type={StyledLinkTypes.TERTIARY}
						//className={`${classes['header-cancel']} text-sm-325 d-flex align-items-center `}
						onClick={() => {
							setCancelModal(true);
						}}
					>
						Cancel
					</StyledLink>
				)}
			</div>

			{showStep()}
		</>
	);
};
