import classes from './Banner.module.scss';
import { BannerTypes, BannerColor } from './Banner.constants';
import React, { SVGProps } from 'react';
import { getClassNames } from '../../../utils/Css';

interface SVGRProps {
	title?: string;
	titleId?: string;
}

type BannerProps = {
	type?: string;
	title?: string;
	fill?: boolean;
	icon?: (props: SVGProps<SVGSVGElement> & SVGRProps) => JSX.Element;
	onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
	onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	children?: JSX.Element | Array<JSX.Element> | string | boolean;
};

export const Banner = ({
	type,
	title,
	fill = false,
	icon: Icon,
	onFocus,
	onClick,
	children
}: BannerProps) => {
	let iconColor = '';
	// style based on banner type
	const getClassByBannerType = (bannerType: string) => {
		let cssClass = '';
		switch (bannerType) {
			case BannerTypes.INFO:
				cssClass = fill ? classes['fill-banner-info'] : classes['banner-info'];
				iconColor = fill ? BannerColor.FILL_LIGHT : BannerColor.INFO;
				break;
			case BannerTypes.POSITIVE:
				cssClass = fill ? classes['fill-banner-positive'] : classes['banner-positive'];
				iconColor = fill ? BannerColor.FILL_DARK : BannerColor.POSITIVE;
				break;
			case BannerTypes.WARNING:
				cssClass = fill ? classes['fill-banner-warning'] : classes['banner-warning'];
				iconColor = fill ? BannerColor.FILL_DARK : BannerColor.WARNING;
				break;
			case BannerTypes.NEGATIVE:
				cssClass = fill ? classes['fill-banner-negative'] : classes['banner-negative'];
				iconColor = fill ? BannerColor.FILL_DARK : BannerColor.NEGATIVE;
				break;
			default:
				cssClass = fill ? classes['fill-banner-info'] : classes['banner-info'];
				iconColor = fill ? BannerColor.FILL_LIGHT : BannerColor.INFO;
				break;
		}
		return cssClass;
	};

	// Get all the classes of banner by using util
	const classNames = getClassNames(getClassByBannerType(type || BannerTypes.INFO));

	// Banner JSX code snippet
	return (
		<div className={classNames} onClick={onClick} onFocus={onFocus}>
			{Icon && (
				<span className={classes.icon}>
					{<Icon color={iconColor} width={30} height={30} aria-hidden></Icon>}
				</span>
			)}
			<span className="d-flex" aria-live="assertive" aria-atomic>
				<p className={fill ? classes['fill-title'] : classes.title}>{title}</p>
				{children}
			</span>
		</div>
	);
};
