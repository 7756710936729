import React, { SVGProps } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './MyAccountDetail.module.scss';
import { ChevronClose } from '../icons';
import { Button, ButtonTypes } from '../common/Button';
import { dateUtil } from '../../utils/dateUtil';
import { getDecimalVal } from '../../utils/numberUtil';
import { AccountPlanStatus } from '../../constants/common';
import { Badge, BadgeTypes } from '../common/Badge';

type DetailsProps = {
	cardActivated: boolean | any;
	details: Array<{
		icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
		text: string;
		textDetail?: string;
		balance?: string;
		amountDetail?: string;
		button?: string;
		buttonHandler?: () => void;
		link?: string;
		summary?: any;
		isDisabled?: boolean;
	}>;
};

const accountBadge = (summaryItem: any) => {
	if (!summaryItem?.plan_status) return;
	if (
		dateUtil
			.day()
			.isBetween(
				dateUtil.day(summaryItem.plan_end_date).subtract(30, 'day').format('YYYY-MM-DD'),
				summaryItem.plan_end_date,
				'day',
				'(]'
			) &&
		summaryItem.plan_status == AccountPlanStatus.ACTIVE
	) {
		return (
			<Badge type={BadgeTypes.WARNING}>
				<p className={`text-xs-325`}>
					Ends {dateUtil.getFormattedDate(summaryItem.grace_period_end_date, 'MMM DD, YYYY')}
				</p>
			</Badge>
		);
	}
	switch (summaryItem.plan_status) {
		case AccountPlanStatus.GRACE:
			return (
				<Badge type={BadgeTypes.WARNING}>
					<p className={`text-xs-325`}>
						Ends {dateUtil.getFormattedDate(summaryItem.grace_period_end_date, 'MMM DD, YYYY')}
					</p>
				</Badge>
			);
		case AccountPlanStatus.READY_TO_CLOSE:
		case AccountPlanStatus.PERM_CLOSED:
			return (
				<p className={`text-sm-325 ${classes['text-gray']}`}>
					Ends {dateUtil.getFormattedDate(summaryItem.grace_period_end_date, 'MMM DD, YYYY')}
				</p>
			);
		case AccountPlanStatus.RUNOUT:
			return (
				<Badge type={BadgeTypes.WARNING}>
					<p className={`text-xs-325`}>In runout period</p>
				</Badge>
			);
		case AccountPlanStatus.SUSPENDED:
			return (
				<Badge type={BadgeTypes.WARNING}>
					<p className={`text-xs-325`}>Suspended</p>
				</Badge>
			);
		case AccountPlanStatus.ENROLLED:
			return (
				<Badge type={BadgeTypes.SECONDARY}>
					<p className={`text-xs-325`}>
						Starts {dateUtil.getFormattedDate(summaryItem.plan_start_date, 'MMM DD, YYYY')}
					</p>
				</Badge>
			);
		default:
			return;
	}
};

export const MyAccountDetail = ({ details, cardActivated }: DetailsProps) => {
	const navigate = useNavigate();
	return (
		<div className="d-flex flex-column">
			<div className={`${classes['title-row']} `}>
				<h5
					className={`text-h5-350 d-flex align-items-center ${
						!cardActivated ? classes['text-yellow'] : ''
					}`}
				>
					My Accounts
				</h5>
				<p
					className={`text-sm-325 d-flex align-items-center text-primary ${
						!cardActivated ? classes['text-white'] : ''
					}`}
				>
					As of {dateUtil.getFormattedDate(new Date(), 'ddd MMMM DD hh:mm A')}
				</p>
			</div>
			<ul className="p-0 m-0">
				{details.map((detail, index) => {
					// To hide the investment tile
					// if (detail.isDisabled) {
					// 	return null;
					// }
					return (
						<li
							key={`activity-detail-${index}`}
							className={`${
								classes['detail-item']
							} d-flex flex-row justify-content-between p-md-32 p-16 align-items-center ${
								(detail.summary?.plan_status == AccountPlanStatus.ENROLLED || detail.isDisabled) &&
								classes['cursor-none']
							}`}
							onClick={() => {
								if (detail.summary?.plan_status == AccountPlanStatus.ENROLLED) {
									return;
								} else if (detail?.link && !detail.button) {
									navigate(detail.link);
								} else if (detail.button && detail.link && detail?.buttonHandler) {
									detail.buttonHandler();
								} else if (detail.button && detail.link) {
									navigate(detail.link);
								}
							}}
							// * Using this onKeyDown listener for now - to make it keyboard accessible
							// TODO | Better Suggestion : Use the IconNavLink component here - instead of this normal div...
							tabIndex={0}
							onKeyDown={e => {
								if (e.key == 'Enter' || e.key == ' ') {
									e.preventDefault();
									e.currentTarget.click();
								}
							}}
						>
							<div className="d-flex flex-row">
								<div className="d-flex align-items-center pr-16">
									<detail.icon width={32} height={32} aria-hidden />
								</div>
								<div className="d-flex flex-column align-self-center">
									<p className="text-base-700 text-primary my-1">{detail.text}</p>

									{accountBadge(detail.summary)}
								</div>
							</div>
							<div className="d-flex flex-row">
								<div className="d-flex flex-column align-items-end justify-content-center">
									{detail.button ? (
										<Button
											onClick={() => {
												if (detail?.buttonHandler) {
													detail.buttonHandler();
												} else if (detail?.link) {
													navigate(detail.link);
												}
											}}
											type={ButtonTypes.TERTIARY}
										>
											{detail.button}
										</Button>
									) : (
										<>
											{(detail.balance || detail.balance == '0') && (
												<p
													className={`text-base-700 text-primary ${
														detail.summary?.plan_status == AccountPlanStatus.SUSPENDED &&
														classes['disabled-amount']
													}`}
												>
													$ {getDecimalVal(Number(detail.balance))}
												</p>
											)}
											{/* {detail.amountDetail && <p className="text-sm-325">{detail.amountDetail}</p>} */}
										</>
									)}
								</div>
								<div className="pl-16 d-flex align-items-center">
									{detail.summary?.plan_status == AccountPlanStatus.ENROLLED ? (
										<span className="px-12"></span>
									) : (
										<ChevronClose titleId={`right-icon-${index}`} title="Go to the details" />
									)}
								</div>
							</div>
						</li>
					);
				})}
			</ul>
		</div>
	);
};
