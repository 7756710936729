import { CustomCalendarIcon, CustomDatePickerHeader } from '../components/common/DatePickerHeader';

export const getDatepickerProps = (id: string, icon = <CustomCalendarIcon />): any => {
	return {
		id,
		showPopperArrow: false,
		popperPlacement: 'bottom-start',
		dropdownMode: 'select',
		showMonthDropdown: true,
		showYearDropdown: true,
		preventOpenOnFocus: true,
		showIcon: true,
		icon,
		dateFormat: 'MM/dd/yyyy',
		toggleCalendarOnIconClick: true,
		onCalendarOpen: () => {
			const element = document.getElementById(`month-${id}`);
			element?.focus();
		},
		renderCustomHeader: (props: any) => <CustomDatePickerHeader {...props} datePickerId={id} />,
		ariaDescribedBy: `error${id}`
	};
};
