/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
// import classes from './ConfirmAddress.module.scss';
import { Button, ButtonTypes } from '../../common/Button';
import { StyledLink } from '../../common/Link';
import { Incomplete, Profile } from '../../../components/icons';
import Modal from 'react-modal';
// import { ContactUs } from '../../ContactUs';
// import { Banner, BannerTypes } from '../../common/Banner';
// import { useForm, Controller } from 'react-hook-form';
// import { dateUtil } from '../../../utils/dateUtil';
import { contactApi } from '../../../services/profileService';
import { Loader } from '../../../components/common/Loader';
// import { Input, InputTypes } from '../../common/Input';
// import { FormControl } from '../../common/FormControl';
// import Select from 'react-select';
// import { selectStyles, stateOptions } from '../../../constants/common';
import { expenseApi } from '../../../services/expenseService';
import { getDecimalVal } from '../../../utils/numberUtil';
import { useNavigate } from 'react-router-dom';
// import { ControlType } from '../../common/FormControl/FormControl.constants';

type ExpenseType = {
	expenseFormData: Record<string, string | null>;
	expenseDetailsSuccess: Record<string, string | boolean>;
	attachments: Array<Record<string, any>>;
	evaluateData: any;
	reimburseData: {
		expense_draft_id: string;
		evaluation_type: string;
		reimburse_to: string;
		is_reimburse_now: boolean;
		reimburse_from: string;
	};
	reimburseFormData: {
		from: string;
		secondary_from: string;
		to: string;
		has_hsa: string;
	};
};

type StepProps = {
	nextStep: () => void;
	previousStep: () => void;
	expenseDetails: ExpenseType;
};

type AddressFormValues = {
	residential_address_line_1: string;
	residential_address_line_2: string;
	residential_city: string;
	residential_state: string;
	residential_zip_code: string;
	mailing_address_line_1: string;
	mailing_address_line_2: string;
	mailing_city: string;
	mailing_state: string;
	mailing_zip_code: string;
};

export const ConfirmAddress = ({ nextStep, previousStep, expenseDetails }: StepProps) => {
	const [isLoading, setLoading] = useState(false);
	const [transferModalIsOpen, setTransferModalIsOpen] = useState<boolean>(false);

	const [address, setAddress] = React.useState<AddressFormValues>({
		residential_address_line_1: '',
		residential_address_line_2: '',
		residential_city: '',
		residential_state: '',
		residential_zip_code: '',
		mailing_address_line_1: '',
		mailing_address_line_2: '',
		mailing_city: '',
		mailing_state: '',
		mailing_zip_code: ''
	});
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [userInfo, setUserInfo] = useState({
		name: '',
		address: '',
		email: '',
		phone_number: ''
	});

	const getPersonalDetails = () => {
		setLoading(true);
		contactApi
			.contactInformation({ 'x-api-version': 2.1 })
			.then((response: any) => {
				const userData = response.data.data;
				setUserInfo({
					name: `${userData.first_name} ${userData.last_name}`,
					address: `${userData.address_line_1 || ''}, ${userData.address_line_2 || ''}, 
					${userData.city || ''}, ${userData.state || ''} ${userData.zip_code || ''}`,
					email: userData.personal_email.toLowerCase(),
					phone_number: userData.phone_number
				});
				setAddress(userData);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	};

	const haveEnoughBalance = () => {
		const selectedAccount = Object.values(expenseDetails.evaluateData.primary_from).find(
			(value: any) => value.account == expenseDetails.reimburseData.reimburse_from
		);
		return (selectedAccount as { can_reimburse_full_amount: boolean }).can_reimburse_full_amount;
	};

	const createExpense = () => {
		setLoading(true);
		const postData: any = { ...expenseDetails.reimburseData };
		if (postData.reimburse_from) {
			delete postData?.reimburse_from;
		}

		expenseApi
			.createExpense(postData)
			.then(() => {
				setLoading(false);

				if (expenseDetails.reimburseData.reimburse_from == 'HSA' && !haveEnoughBalance()) {
					navigate('/apps/expenses/summary');
				} else {
					nextStep();
				}
			})
			.catch(() => {
				setLoading(false);
			});
	};
	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			width: '100%',
			maxWidth: '565px',
			transform: 'translate(-50%, -50%)',
			padding: '0px',
			border: 'none'
			// padding: '56px 24px',
			// backgroundColor: '#001b47',
			// borderColor: '#001b47'
		}
	};

	useEffect(() => {
		getPersonalDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const submitButton = () => {
		if (expenseDetails.reimburseData.reimburse_from == 'HSA' && haveEnoughBalance()) {
			return (
				<Button
					onClick={() => {
						setTransferModalIsOpen(true);
					}}
				>
					Reimburse Now
				</Button>
			);
		} else if (expenseDetails.reimburseData.reimburse_from == 'HSA' && !haveEnoughBalance()) {
			return (
				<Button
					onClick={() => {
						createExpense();
					}}
				>
					Save to Reimburse Later
				</Button>
			);
		} else {
			return (
				<Button
					onClick={() => {
						createExpense();
					}}
				>
					Submit Expense
				</Button>
			);
		}
	};
	if (isLoading) {
		return <Loader />;
	}
	return (
		<React.Fragment>
			<Modal
				isOpen={transferModalIsOpen}
				style={customStyles}
				onRequestClose={() => {
					setTransferModalIsOpen(false);
				}}
				contentLabel="Transfer Modal"
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
				ariaHideApp={false}
			>
				<div>
					<div className="modal-header">
						<div
							onClick={() => {
								setTransferModalIsOpen(false);
							}}
							className="modal-close-btn-container"
						>
							<Incomplete></Incomplete>
						</div>
						<div className={`text-h3-325 text-white text-center mt-32 `}>Confirm Transfer</div>
					</div>
					<div className="modal-body d-flex flex-column align-items-center">
						<p className={`px-64 py-16 text-center text-base-325 text-primary`}>
							A check for ${getDecimalVal(expenseDetails.evaluateData.amount_to_reimburse)} will be
							written and sent to your mailing address. Do you wish to proceed with your
							reimbursement?
						</p>
						<div className={`d-flex flex-row flex mt-16 gap-20`}>
							<Button
								type={ButtonTypes.SECONDARY}
								onClick={() => {
									setTransferModalIsOpen(false);
								}}
							>
								Close
							</Button>
							<Button
								type={ButtonTypes.PRIMARY}
								onClick={() => {
									setTransferModalIsOpen(false);
									createExpense();
								}}
							>
								Yes
							</Button>
						</div>
					</div>
				</div>
			</Modal>
			<div className="d-flex flex-column mt-64">
				<p className={'text-h5-325 text-primary'}>Confirm Mailing Address</p>
				<p className="text-base-325 py-12 text-primary">
					We will mail the check to the address connected to your account:
				</p>
				<p className="text-base-700 text-primary">{userInfo?.name}</p>
				<p className="text-base-325 text-primary">{address.mailing_address_line_1}</p>
				{address.mailing_address_line_2 && (
					<p className="text-base-325 text-primary">{address.mailing_address_line_2}</p>
				)}
				<p className="text-base-325 text-primary">
					{address.mailing_city}, {address.mailing_state} {address.mailing_zip_code}
				</p>
				<div className="py-32">
					<hr aria-hidden="true" />
					<p className="text-base-325 mt-16 mb-12 text-primary d-flex justify-content-center">
						Not the correct Address?
					</p>

					<div className="d-flex justify-content-center align-items-center py-12 ">
						<Profile color={'#296DB2'} />
						<span className="px-4">
							<StyledLink
								onClick={() => {
									navigate('/apps/update-address', {
										state: { expenseData: expenseDetails, address: address }
									});
								}}
							>
								<span className="text-base-350">Update Your Account Information</span>
							</StyledLink>
						</span>
					</div>

					<hr aria-hidden="true" />
					<br />
				</div>

				{submitButton()}
				<br />
				<Button type={ButtonTypes.SECONDARY} onClick={previousStep}>
					Back
				</Button>
			</div>
		</React.Fragment>
	);
};
