// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
}
const SvgExclamationTriangle = ({
	title,
	titleId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 18 16"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fill={props.color || `#111827`}
			d="M17 15.75H1a.76.76 0 0 1-.65-.37.77.77 0 0 1 0-.75l8-14a.78.78 0 0 1 1.3 0l8 14a.77.77 0 0 1 0 .75.76.76 0 0 1-.65.37Zm-14.71-1.5h13.42L9 2.51 2.29 14.25Z"
		/>
		<path
			fill={props.color || `#111827`}
			d="M9 10.25a.76.76 0 0 1-.75-.75V6a.75.75 0 0 1 1.5 0v3.5a.76.76 0 0 1-.75.75ZM9 13.25a.76.76 0 0 1-.75-.75V12a.75.75 0 1 1 1.5 0v.5a.76.76 0 0 1-.75.75Z"
		/>
	</svg>
);
export default SvgExclamationTriangle;
