/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import classes from './Reimburse.module.scss';
import { useForm, Controller } from 'react-hook-form';
import { FormControl } from '../../common/FormControl';
import Select from 'react-select';
import {
	expenseTypeOptions,
	ReimburseTo,
	EvaluationType,
	ReimburseExpenseType,
	AccountType
} from '../../../constants/common';

import { getDecimalVal } from '../../../utils/numberUtil';
import { Button, ButtonSizes, ButtonTypes } from '../../common/Button';
import { Banner, BannerTypes } from '../../common/Banner';
import Warning from '../../icons/Warning';
import { Loader } from '../../../components/common/Loader';
import { expenseApi } from '../../../services/expenseService';
import { usePlaid } from '../../../hooks/usePlaid';
import Modal from 'react-modal';
import { Incomplete } from '../../icons';
import { StyledLink } from '../../common/Link';
import linkBankAccountError from '../../../assets/img/error.svg';
import ConfirmationImage from '../../../assets/img/ThumbsUpLight.svg';
import { ContactUs } from '../../ContactUs';
import { getAccountSummary } from '../../../store/accountSummarySlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateLiveRegionData } from '../../../store/backgroundSlice';
import { onFocus } from '../CreateExpense.util';
import { getSelectStyles } from '../../../utils/selectUtils';

type ReimburseData = {
	expense_draft_id: string;
	evaluation_type: string;
	reimburse_to: string;
	reimburse_from: string;
	is_reimburse_now: boolean;
};

type FormValues = {
	from: string;
	secondary_from: string;
	to: string;
	has_hsa: string;
};

type StepProps = {
	nextStep: (increaseBy?: number) => void;
	previousStep: () => void;
	expenseDetails: {
		expenseDetailsSuccess: Record<string, string | boolean>;
		evaluateData: any;
		reimburseData: ReimburseData;
		reimburseFormData: FormValues;
	};
	setReimburseData: (reimburseData: ReimburseData) => void;
	updateEvaluateData: (evaluateData: any) => void;
	updateReimburseFormData: (reimburseFormData: any) => void;
};

export const Reimburse = ({
	nextStep,
	previousStep,
	expenseDetails,
	setReimburseData,
	updateEvaluateData,
	updateReimburseFormData
}: StepProps) => {
	const {
		//register,
		setValue,
		getValues,
		control,
		handleSubmit,
		watch,
		formState: {}
	} = useForm<FormValues>({
		mode: 'onBlur',

		defaultValues: expenseDetails.reimburseFormData.from
			? expenseDetails.reimburseFormData
			: {
					from: '',
					secondary_from: '',
					to: '',
					has_hsa: 'Y'
			  }
	});

	const { openPlaid, networkResponse, networkError, networkLoading } = usePlaid('bank-account');
	const [invalidAccountModalIsOpen, setInvalidAccountModalIsOpen] = useState<boolean>(false);
	const [transferModalIsOpen, setTransferModalIsOpen] = useState<boolean>(false);
	const [primaryFromOptions, setPrimaryFromOptions] = useState<Array<any>>([]);
	const [secondaryFromOptions, setSecondaryFromOptions] = useState<Array<any>>([]);
	const [toOptions, setToOptions] = useState<Array<any>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isContactUsOpen, setIsContactUsOpen] = useState<boolean>(false);
	const [linkSuccessModalIsOpen, setLinkSuccessModalIsOpen] = useState<boolean>(false);

	const dispatch = useDispatch<any>();
	const navigate = useNavigate();
	const fromInput = watch('from');
	const toInput = watch('to');
	const HasHsaInput = watch('has_hsa');

	const summaryData = useSelector((state: any) => state.summaryData);

	const getEvaluated = (evaluatedType?: string) => {
		setIsLoading(true);
		window.scrollTo(0, 0);
		const requestQuery = {
			expense_draft_id: expenseDetails.expenseDetailsSuccess.expense_draft_id,
			evaluation_type: evaluatedType || EvaluationType.DEFAULT
		};
		expenseApi
			.getEvaluate(requestQuery)
			.then(response => {
				updateEvaluateData(response.data);
				setIsLoading(false);
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	const getDefaultSelectedPrimaryFrom = () => {
		const primaryDefaultAccountType = expenseDetails.evaluateData.primary_default_account_type;
		const account = expenseDetails.evaluateData.primary_from[primaryDefaultAccountType];
		setValue('from', account.account);
		return {
			value: account.account,
			label: `${account.account}`,
			current_balance: account.current_balance
		};
	};

	const getDefaultTo = () => {
		const externalAccount = expenseDetails.evaluateData.reimburse_to_options.find(
			(toOption: { key: string }) => toOption.key == ReimburseTo.EXTERNAL
		);
		if (externalAccount.text != 'LINK') {
			setValue('to', externalAccount.key);
			return {
				value: externalAccount.key,
				label: externalAccount.text
			};
		} else {
			const account = expenseDetails.evaluateData.reimburse_to_options[0];
			setValue('to', account.key);
			return {
				value: account.key,
				label: `${account.text}`
			};
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		dispatch(getAccountSummary());
	}, [dispatch]);

	useEffect(() => {
		if (networkError && networkError.response.data.error.code == 'NAME_DOES_NOT_MATCH') {
			setInvalidAccountModalIsOpen(true);
		}
	}, [networkError]);

	useEffect(() => {
		if (networkResponse) {
			setLinkSuccessModalIsOpen(true);
		}
	}, [networkResponse]);

	useEffect(() => {
		const primaryFromObj = JSON.parse(JSON.stringify(expenseDetails.evaluateData.primary_from));

		setPrimaryFromOptions(
			Object.values(primaryFromObj).map((account: any) => {
				return {
					value: account.account,
					label: `${account.account}`,
					current_balance: account.current_balance
				};
			})
		);

		setSecondaryFromOptions(
			expenseDetails.evaluateData.secondary_from.map((account: any) => {
				return {
					value: account.account,
					label: `${account.account}`,
					current_balance: account.current_balance
				};
			})
		);

		setToOptions(
			expenseDetails.evaluateData.reimburse_to_options.map((option: any) => {
				return {
					value: option.key,
					label: option.text
				};
			})
		);
	}, [expenseDetails.evaluateData]);

	const getDefaultPrimaryAccount = () => {
		const primaryDefaultAccount = expenseDetails.evaluateData.primary_default_account_type;
		return expenseDetails.evaluateData.primary_from[primaryDefaultAccount];
	};

	const getPrimaryFromSelected = () => {
		if (fromInput) {
			return Object.values(expenseDetails.evaluateData.primary_from).find(
				(value: any) => value.account == fromInput
			);
		} else {
			return getDefaultPrimaryAccount();
		}
	};

	const haveEnoughBalance = () => {
		const selectedAccount = Object.values(expenseDetails.evaluateData.primary_from).find(
			(value: any) => value.account == fromInput
		);
		if (selectedAccount) {
			return (selectedAccount as { can_reimburse_full_amount: boolean }).can_reimburse_full_amount;
		} else {
			return false;
		}
	};

	const getSelectedFromComment = () => {
		const selectedAccount: any = Object.values(expenseDetails.evaluateData.primary_from).find(
			(value: any) => value.account == fromInput
		);
		return selectedAccount?.comment as string;
	};

	const submitButton = () => {
		if (toInput && toInput == ReimburseTo.CHECK) {
			return 'Confirm Mailing Address';
		} else if (fromInput == 'HSA' && haveEnoughBalance()) {
			return 'Reimburse Now';
		} else if (fromInput == 'HSA' && !haveEnoughBalance()) {
			return 'Save to Reimburse Later';
		} else {
			return 'Submit Expense';
		}
	};
	const formatToOptionLabel = ({ value, label }: { value: string; label: string }) => (
		<div className="d-flex justify-content-between align-items-center ">
			<div>
				{label == 'LINK'
					? 'External Account'
					: value == ReimburseTo.EVERYDAY
					? label.split('$')[0]
					: label}
			</div>
			{label == 'LINK' ? (
				<div className={`d-flex align-items-center text-base-700`}>
					<Button type={ButtonTypes.TERTIARY} size={ButtonSizes.COMPACT} onClick={openPlaid}>
						Link Account
					</Button>
				</div>
			) : (
				''
			)}

			{value == ReimburseTo.EVERYDAY ? (
				<div className={`d-flex align-items-center `}>
					Balance ${getDecimalVal(+label.split('$')[1]) || '00.00'}
				</div>
			) : (
				''
			)}
		</div>
	);
	const formatFromOptionLabel = (option: any) => (
		<div className="d-flex justify-content-between align-items-center ">
			<div>{option.label}</div>
			<div className={`d-flex align-items-center  `}>
				Balance ${getDecimalVal(option.current_balance)}
			</div>
		</div>
	);

	const formatSecondaryFromOptionLabel = (option: any) => (
		<div className="d-flex justify-content-between align-items-center ">
			<div>{option.label}</div>
			<div className={`d-flex align-items-center  `}>
				Balance ${getDecimalVal(expenseDetails.evaluateData?.HSA?.current_balance)}
			</div>
		</div>
	);

	const getFromCaption = () => {
		if (
			fromInput == 'HSA' &&
			expenseDetails?.evaluateData?.primary_from?.HSA.current_balance == 0
		) {
			return '';
		} else if (
			fromInput.includes('DCFSA') &&
			expenseDetails?.evaluateData?.primary_from?.DCFSA.current_balance == 0 &&
			summaryData.data.xfsa_summary.find(
				(account: { type: string }) => account.type == AccountType.DCFSA
			)?.planned_balance_amount > 0
		) {
			return '';
		} else if (fromInput == 'HSA') {
			return `Remaining Balance after reimbursement: $ ${getDecimalVal(
				getPrimaryFromSelected()?.balance_post_debit || 0
			)}`;
		} else {
			return `Remaining Balance after submission: $ ${getDecimalVal(
				getPrimaryFromSelected()?.balance_post_debit || 0
			)}`;
		}
	};

	const submitReimbursement = (reimburseLater?: boolean) => {
		const reimburseQuery: any = {
			expense_draft_id: expenseDetails.expenseDetailsSuccess.expense_draft_id as string,
			evaluation_type:
				expenseDetails.evaluateData.display_hsa && getValues('has_hsa') == 'N'
					? EvaluationType.OPT_OUT_HSA
					: expenseDetails.evaluateData.evaluation_type,
			reimburse_to: toInput,
			is_reimburse_now: fromInput == 'HSA' && haveEnoughBalance()
		};

		if (reimburseLater) {
			reimburseQuery.is_reimburse_now = false;
			setIsLoading(true);
			expenseApi
				.createExpense(reimburseQuery)
				.then(() => {
					dispatch(updateLiveRegionData('Expense was created successfully'));
					setTimeout(() => {
						dispatch(updateLiveRegionData(''));
					}, 5000);
					navigate('/apps/expenses/summary');
				})
				.catch(() => {
					setIsLoading(false);
				});
			return;
		}

		setReimburseData({ ...reimburseQuery, reimburse_from: fromInput });

		if (toInput && toInput == ReimburseTo.CHECK) {
			nextStep();
		} else {
			setIsLoading(true);
			expenseApi
				.createExpense(reimburseQuery)
				.then(() => {
					setIsLoading(false);
					if (fromInput == 'HSA' && !haveEnoughBalance()) {
						dispatch(updateLiveRegionData('Expense was created successfully'));
						setTimeout(() => {
							dispatch(updateLiveRegionData(''));
						}, 5000);
						navigate('/apps/expenses/summary');
					} else {
						nextStep(2);
					}
				})
				.catch(() => {
					setIsLoading(false);
				});
		}
	};
	const onSubmit = handleSubmit((data: FormValues) => {
		updateReimburseFormData(data);
		if (fromInput == 'HSA' && haveEnoughBalance()) {
			setTransferModalIsOpen(true);
			return;
		}
		submitReimbursement();
	});

	if (isLoading || networkLoading) {
		return <Loader />;
	}
	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			width: '100%',
			maxWidth: '560px',
			transform: 'translate(-50%, -50%)',
			padding: '0px',
			border: 'none'
			//padding: '56px 24px'
		}
	};

	const isDisableSubmit = () => {
		return (
			!toInput ||
			!!toOptions.find(toOption => toInput == ReimburseTo.EXTERNAL && toOption.label == 'LINK')
		);
	};

	return (
		<>
			<Modal
				isOpen={invalidAccountModalIsOpen}
				style={customStyles}
				onRequestClose={() => {
					setInvalidAccountModalIsOpen(false);
				}}
				contentLabel="Link Error Modal"
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
				ariaHideApp={false}
			>
				<div>
					<div className="modal-header">
						<div
							onClick={() => {
								setInvalidAccountModalIsOpen(false);
							}}
							className="modal-close-btn-container"
						>
							<Incomplete></Incomplete>
						</div>
						<div className={`text-white text-center mt-32 text-h3-325`}>UH OH!</div>
					</div>
					<div className="modal-body d-flex flex-column align-items-center">
						<img src={linkBankAccountError} width="180px"></img>

						<p className={`px-64 py-16 text-base-325`}>
							The name on the bank account you are trying to link does not match the name on your
							InComm Benefit account.
						</p>
						<p className={`px-64 pt-4 text-base-325`}>
							For security purposes we aren’t able to link accounts unless account names match
							exactly.
						</p>
						<div className={`d-flex flex-row flex mt-32 gap-20`}>
							<Button
								onClick={() => {
									setInvalidAccountModalIsOpen(false);
								}}
							>
								TRY ANOTHER ACCOUNT
							</Button>
						</div>
						<p className={'py-16 text-base-325'}>
							Need Help?{' '}
							<StyledLink
								//href="mailto:support@incommbenefits.com"
								onClick={() => {
									setIsContactUsOpen(true);
								}}
							>
								Contact Us
							</StyledLink>
						</p>
					</div>
				</div>
			</Modal>
			<Modal
				isOpen={linkSuccessModalIsOpen}
				style={customStyles}
				onRequestClose={() => {
					setLinkSuccessModalIsOpen(false);
				}}
				contentLabel="Link Success Modal"
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
				ariaHideApp={false}
			>
				<div>
					<div className="modal-header">
						<div
							onClick={() => {
								setLinkSuccessModalIsOpen(false);
								dispatch(getAccountSummary());
								getEvaluated();
							}}
							className="modal-close-btn-container"
						>
							<Incomplete></Incomplete>
						</div>
						<div className={`text-h2-325 text-white mt-32 text-center`}>Success!</div>
					</div>
					<div className="modal-body d-flex flex-column align-items-center">
						<img src={ConfirmationImage} width="180px"></img>
						<p className={`px-64 py-16 text-base-325`}>
							Your bank account is now linked to InComm Benefits! Now we need you to fund your
							spending account in order to fund your regular purchases.
						</p>
						<div className={`d-flex flex-row flex mt-16 gap-20`}>
							<Button
								onClick={() => {
									setLinkSuccessModalIsOpen(false);
									dispatch(getAccountSummary());
									getEvaluated();
								}}
							>
								Continue
							</Button>
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				isOpen={transferModalIsOpen}
				style={customStyles}
				onRequestClose={() => {
					setTransferModalIsOpen(false);
				}}
				contentLabel="Transfer Modal"
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
				ariaHideApp={false}
			>
				<div>
					<div className="modal-header">
						<div
							onClick={() => {
								setTransferModalIsOpen(false);
							}}
							className="modal-close-btn-container"
						>
							<Incomplete></Incomplete>
						</div>
						<div className={`text-h3-325 text-white text-center mt-32`}>Confirm Transfer</div>
					</div>
					<div className="modal-body d-flex flex-column align-items-center">
						<p className={`px-64 py-16 text-base-325 text-center`}>
							${getDecimalVal(expenseDetails.evaluateData.amount_to_reimburse)} will move out of
							your HSA and into your {toInput == ReimburseTo.EXTERNAL && 'linked bank'}{' '}
							{toInput == ReimburseTo.EVERYDAY && 'HSA Backup'} account. Do you wish to proceed with
							your reimbursement?
						</p>
						<div className={`d-flex flex-row flex mt-16 gap-20`}>
							<Button
								onClick={() => {
									setTransferModalIsOpen(false);
									submitReimbursement();
								}}
							>
								Yes
							</Button>
							<Button
								type={ButtonTypes.SECONDARY}
								onClick={() => {
									setTransferModalIsOpen(false);
								}}
							>
								Close
							</Button>
						</div>
					</div>
				</div>
			</Modal>
			<ContactUs
				openModal={isContactUsOpen}
				closeModal={() => {
					setIsContactUsOpen(false);
				}}
				eventPage="Reimburse - Account Linking - Name Mismatch"
			/>

			<div className="d-flex flex-column mt-64">
				<h5 className={'text-h5-325 text-primary'}>Reimbursement Breakdown</h5>
				<div className="py-32 cl-md-6">
					{expenseDetails.evaluateData?.health_expense_details?.expense_type !=
						ReimburseExpenseType.TRAVEL && (
						<div className="d-flex flex-row justify-content-between">
							<p className="text-base-325 text-primary">
								{expenseDetails.evaluateData?.health_expense_details?.expense_type} Expense
							</p>
							<p className="text-base-325 text-primary">
								${getDecimalVal(expenseDetails.evaluateData?.health_expense_details?.amount)}
							</p>
						</div>
					)}
					{expenseDetails.evaluateData?.health_expense_details?.expense_type !=
						ReimburseExpenseType.DEPENDENT_CARE && (
						<>
							<div className="d-flex flex-row justify-content-between py-12">
								<p className="text-base-325 text-primary">
									Travel –{' '}
									{getDecimalVal(expenseDetails.evaluateData?.travel_expense_details?.mileage)}{' '}
									miles @ $
									{getDecimalVal(
										expenseDetails.evaluateData?.travel_expense_details?.cost_per_mile
									)}
								</p>
								<p className="text-base-325 text-primary">
									$
									{getDecimalVal(
										expenseDetails.evaluateData.travel_expense_details?.mileage *
											expenseDetails.evaluateData?.travel_expense_details?.cost_per_mile
									)}
								</p>
							</div>
							<div className="d-flex flex-row justify-content-between">
								<p className="text-base-325 text-primary">Other Travel Expenses</p>
								<p className="text-base-325 text-primary">
									$
									{getDecimalVal(
										expenseDetails.evaluateData.travel_expense_details?.other_travel_expense_amount
									)}
								</p>
							</div>
						</>
					)}

					<hr aria-hidden="true" className="mt-12" />
					<div className="d-flex flex-row justify-content-between py-12">
						<strong className="text-base-700 text-primary">Amount to Reimburse</strong>
						<strong className="text-base-700 text-primary">
							$ {getDecimalVal(expenseDetails?.evaluateData?.amount_to_reimburse)}
						</strong>
					</div>
					<hr aria-hidden="true" />
				</div>
				<h5 className="text-h5-325 text-primary">Account Reimbursement</h5>
				<form onSubmit={onSubmit} className="py-16">
					<div className="d-flex flex-column">
						<div className="row">
							<div className="cl-md-6">
								<Controller
									control={control}
									name="from"
									rules={{
										required: 'From is required.'
									}}
									render={({ field: { value }, fieldState: { error } }) => (
										<>
											<FormControl
												label={() => 'From'}
												error={() => error?.message || ''}
												caption={() =>
													(expenseDetails?.evaluateData?.primary_default_account_type != 'HSA' ||
														haveEnoughBalance()) && (
														<span
															className={
																getPrimaryFromSelected()?.balance_post_debit == 0
																	? classes['error-text']
																	: ''
															}
														>
															{getFromCaption()}
														</span>
													)
												}
											>
												<Select
													options={primaryFromOptions}
													onChange={selected => {
														setValue('from', selected?.value || '');
														if (selected?.value.includes('HSA')) {
															getEvaluated(EvaluationType.HSA_ONLY);
														}
													}}
													placeholder={'Select Account'}
													value={
														value
															? primaryFromOptions.find(item => item.value == value)
															: getDefaultSelectedPrimaryFrom()
													}
													isSearchable={false}
													styles={getSelectStyles()}
													isDisabled={primaryFromOptions?.length == 1}
													formatOptionLabel={formatFromOptionLabel}
												/>
											</FormControl>
										</>
									)}
								/>

								{fromInput && getSelectedFromComment() ? (
									<Banner fill type={BannerTypes.WARNING} icon={Warning}>
										<span
											className={`text-sm-325 align-self-center text-primary ${classes['underline-none']}`}
										>
											{getSelectedFromComment()}
										</span>
									</Banner>
								) : (
									''
								)}

								{expenseDetails.evaluateData?.secondary_from?.length ? (
									<div className="pt-8">
										<Controller
											control={control}
											name="secondary_from"
											rules={
												{
													//required: 'From is required.'
												}
											}
											render={({ field: { value }, fieldState: { error } }) => (
												<>
													<FormControl
														label={() => 'From'}
														error={() => error?.message || ''}
														caption={() => (
															<span
																className={
																	(expenseDetails.evaluateData.secondary_from[0]
																		?.balance_post_debit || 0) == 0
																		? classes['error-text']
																		: ''
																}
															>
																Remaining Balance after processing: $
																{getDecimalVal(
																	expenseDetails.evaluateData.secondary_from[0]
																		?.balance_post_debit || 0
																)}
															</span>
														)}
													>
														<Select
															options={secondaryFromOptions}
															onChange={selected => {
																setValue('secondary_from', selected?.value || '');
															}}
															placeholder={'Select Account'}
															value={
																value
																	? expenseTypeOptions.find(item => item.value == value)
																	: secondaryFromOptions[0]
															}
															isSearchable={false}
															//defaultValue={secondaryFromOptions[0]}
															styles={getSelectStyles()}
															isDisabled={secondaryFromOptions?.length == 1}
															formatOptionLabel={formatFromOptionLabel}
														/>
													</FormControl>
												</>
											)}
										/>
									</div>
								) : (
									''
								)}

								{expenseDetails.evaluateData?.display_hsa ? (
									<div className="py-16">
										<p className={`text-sm-325 text-primary ${classes['underline-none']}`}>
											Would you like the remaining $
											{getDecimalVal(expenseDetails.evaluateData?.HSA?.reimbursable_amount)} to get
											reimbursed from your HSA?
										</p>
										<div className="d-flex py-12">
											<div>
												<Controller
													control={control}
													name="has_hsa"
													rules={{
														required: 'has hsa is required.'
													}}
													render={({ field: {}, fieldState: {} }) => (
														<div className="d-flex align-items-center">
															<input
																className={classes['radio-button']}
																name="hasTravel"
																onChange={e => {
																	if (e.target.checked) {
																		setValue('has_hsa', 'Y');
																	}
																}}
																value={'Y'}
																type="radio"
																checked={getValues('has_hsa') == 'Y'}
															/>
															<span className="text-base-325 px-4">Yes</span>
														</div>
													)}
												/>
											</div>
											<div className="px-16">
												<Controller
													control={control}
													name="has_hsa"
													rules={{
														required: 'has hsa is required.'
													}}
													render={({ field: {}, fieldState: {} }) => (
														<div className="d-flex align-items-center">
															<input
																name="hasTravel"
																className={classes['radio-button']}
																onChange={e => {
																	if (e.target.checked) {
																		setValue('has_hsa', 'N');
																		getEvaluated(EvaluationType.OPT_OUT_HSA);
																	}
																}}
																value={'N'}
																type="radio"
																checked={getValues('has_hsa') == 'N'}
															/>
															<span className="text-base-325 px-4">No</span>
														</div>
													)}
												/>
											</div>
										</div>
										{HasHsaInput == 'Y' ? (
											<FormControl
												label={() => ''}
												error={() => ''}
												caption={`Remaining Balance after processing: $${getDecimalVal(
													expenseDetails.evaluateData?.HSA?.balance_post_debit
												)}`}
											>
												<Select
													options={[
														{
															value: 'HSA',
															label: 'HSA'
														}
													]}
													onChange={selected => {
														setValue('to', selected?.value || '');
													}}
													placeholder={'Select Account'}
													value={{ value: 'HSA', label: 'HSA' }}
													isSearchable={false}
													isDisabled={true}
													styles={getSelectStyles()}
													formatOptionLabel={formatSecondaryFromOptionLabel}
												/>
											</FormControl>
										) : (
											''
										)}
									</div>
								) : (
									''
								)}
							</div>

							<div className="cl-md-6">
								<div>
									<Controller
										control={control}
										name="to"
										rules={{
											required: 'reimburse to account is required.'
										}}
										render={({ field: { value }, fieldState: { error } }) => (
											<>
												<FormControl label={() => 'To'} error={() => error?.message || ''}>
													<Select
														options={toOptions}
														aria-label="Reimburse To"
														ariaLiveMessages={{ onFocus }}
														onChange={selected => {
															setValue('to', selected?.value || '');
														}}
														placeholder={'Select Account'}
														value={
															value ? toOptions.find(item => item.value == value) : getDefaultTo()
														}
														isSearchable={false}
														styles={getSelectStyles()}
														isDisabled={toOptions?.length == 1}
														formatOptionLabel={formatToOptionLabel}
													/>
												</FormControl>
											</>
										)}
									/>
								</div>
							</div>
							<div className="cl-md-6"></div>
						</div>

						<hr aria-hidden="true" />
						<div className={`row ${classes['reverse-order']}`}>
							<div className="cl-md-6 d-flex flex-column py-8">
								<Button onClick={previousStep} type={ButtonTypes.SECONDARY}>
									Back
								</Button>
							</div>
							<div className="cl-md-6 d-flex flex-column py-8">
								<Button isDisabled={isDisableSubmit()}>{submitButton()}</Button>
							</div>
						</div>
					</div>
				</form>
				{fromInput == 'HSA' &&
					expenseDetails.evaluateData?.primary_from?.HSA?.current_balance >=
						expenseDetails?.evaluateData?.amount_to_reimburse && (
						<div className="d-flex pb-16 justify-content-center">
							<StyledLink
								onClick={() => {
									submitReimbursement(true);
								}}
							>
								Reimburse Later
							</StyledLink>
						</div>
					)}

				<p className="text-sm-325 text-primary">
					{expenseDetails.evaluateData?.health_expense_details?.expense_type ==
					ReimburseExpenseType.DEPENDENT_CARE
						? `I certify that I am requesting reimbursement for work-related dependent care expenses incurred by an eligible dependent (for a child under the age of 13 or other dependents that are physically and mentally incapable of taking care of themselves) while I was a participant in the plan. I confirm these services have already been provided and that I have not and will not seek reimbursement of this expense from any other plan or party.`
						: `I certify that I am requesting reimbursement for eligible expenses incurred by myself or
					an eligible dependent while I was a participant in the plan. I have already received these
					products and services and confirm that by requesting reimbursement here that I have not
					and will not seek reimbursement of this expense from any other plan or party.`}
				</p>
			</div>
		</>
	);
	// eslint-disable-next-line max-lines
};
