/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import classes from './TransactionDetailPage.module.scss';
import { ChevronClose, Doc } from '../../components/icons';
// import { StyledLink } from '../../components/common/Link';
// import logo from '../../assets/img/logo.svg';
// import { Banner } from '../../components/common/Banner';
// import {
// 	// ExpenseStatusType,
// 	// ExpenseActionType,
// 	// ExpenseType,
// 	// ExpenseMode
// 	// ReimburseTo,
// 	// AccountType
// 	// ExpenseMode
// } from '../../constants/common';
// import { getDecimalVal } from '../../utils/numberUtil';

import { dateUtil } from '../../utils/dateUtil';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { expenseApi } from '../../services/expenseService';
import { Loader } from '../../components/common/Loader';
import { Banner } from '../../components/common/Banner';
import { Button, ButtonSizes } from '../../components/common/Button';
import { getDecimalVal } from '../../utils/numberUtil';
import { isAccountClosed } from '../../utils/accountUtils';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountSummary } from '../../store/accountSummarySlice';

export const TransactionDetailPage = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const dispatch = useDispatch<any>();
	const [transactionDetails, setTransactionDetails] = useState<any>({});
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isHsaAccountClosed, setIsHsaAccountClosed] = useState<boolean>(false);

	const getTransactionDetails = (transactionId: string) => {
		setIsLoading(true);
		expenseApi
			.getTransactionsDetails(transactionId)
			.then((response: any) => {
				// setStyleByStatus(response.data);
				setTransactionDetails(response.data.data);
				setIsLoading(false);
			})
			.catch(() => {
				navigate('/apps/dashboard');
			});
	};

	const summaryData = useSelector((state: any) => state.summaryData);

	useEffect(() => {
		window.scrollTo(0, 0);
		const transactionId = searchParams.get('id');
		if (!transactionId) {
			navigate('/apps/dashboard');
			return;
		}
		// (window as any).gtag('event', 'screen_view', {
		// 	app_name: 'Zenda-web',
		// 	screen_name: 'Expense'
		// });
		getTransactionDetails(transactionId);
		dispatch(getAccountSummary());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setIsHsaAccountClosed(isAccountClosed(summaryData.data));
	}, [summaryData.data]);

	if (isLoading) {
		return <Loader />;
	}

	return (
		<>
			<div className="mt-64">
				<div className="d-flex align-items-center">
					<div
						className={`d-flex align-items-center ${classes['close-icon']}  px-4`}
						onClick={() => {
							window.scrollTo(0, 0);
							navigate(-1);
						}}
					>
						<ChevronClose color="#eff3fa" />
					</div>
					<div
						className={`d-flex justify-content-center align-items-center w-100 ${classes['expense-header']}`}
					>
						<p className={classes['header-text']}>Transaction Details</p>
					</div>
				</div>

				<div className={`d-flex align-items-center justify-content-between py-16`}>
					<p className={`text-base-325 ${classes['text-white']}`}></p>
					<div className={`px-16 ${classes['gray-button']}`}>
						<Button size={ButtonSizes.COMPACT}>Settled</Button>
					</div>
				</div>
			</div>
			<div className="d-flex flex-column justify-content-center mt-32">
				<p className="text-base-350">
					We were unable to identify an eligible health expense in your recent purchase at{' '}
					{transactionDetails?.merchant_details?.merchant_name}.
				</p>

				<div className="row mt-32">
					<div className={`cl-md-6 cl-12 ${classes.order2}`}>
						<div className="mt-16">
							<p className={`${classes['h4-bold']} mb-16`}>Transaction Summary</p>

							<div className="d-flex justify-content-between">
								<strong className="text-base-700">Transaction Amount</strong>
								<strong className="text-base-700">
									${getDecimalVal(transactionDetails?.amount || 0)}
								</strong>
							</div>
							<div className="d-flex justify-content-between">
								<p>Eligible Amount</p>
								<p>${getDecimalVal(0)}</p>
							</div>
							<div className="d-flex justify-content-between">
								<p>Ineligible Amount</p>
								<p>${getDecimalVal(transactionDetails?.amount || 0)}</p>
							</div>
							<hr aria-hidden="true" className="my-32" />

							<p className={classes['h4-bold']}>Account Breakdown</p>

							<div className="pt-16">
								<div className="d-flex justify-content-between">
									<p>From HSA Backup</p>
									<p>${getDecimalVal(transactionDetails?.amount || 0)}</p>
								</div>
							</div>
							<div className="d-flex justify-content-between">
								<p className="text-base-700">Total Transaction</p>
								<p className="text-base-700">${getDecimalVal(transactionDetails?.amount || 0)}</p>
							</div>

							<hr aria-hidden="true" className="my-32" />

							<p className={classes['h4-bold']}>Transaction Details</p>
							<div className="py-16">
								<div className="d-flex justify-content-between">
									<p>Transaction Date</p>
									<p>{dateUtil.getFormattedDate(transactionDetails?.date * 1000, 'MM/DD/YYYY')}</p>
								</div>
								<div className="d-flex justify-content-between">
									<p>Merchant</p>
									<p>{transactionDetails?.merchant_details?.merchant_name}</p>
								</div>
								<div className="d-flex justify-content-between">
									<p>Transaction For</p>
									<p>{transactionDetails?.card_holder_name}</p>
								</div>
							</div>

							<hr aria-hidden="true" className="my-16" />
						</div>
					</div>
					<div className={`cl-md-6 cl-12`}>
						<div className="pl-32">
							<div className="mb-16">
								<Banner icon={Doc}>
									<div>
										<p className="text-base-700 py-2">Create Expense</p>
										<div className="text-sm-325">
											<p>
												If this was a health expense, you can create an expense and reimburse
												yourself.
											</p>
										</div>
									</div>
								</Banner>
							</div>
							<div className="d-flex justify-content-center flex-column">
								<Button
									isDisabled={isHsaAccountClosed}
									onClick={() => {
										navigate(`/apps/expense/create?transaction_id=${transactionDetails.id}`);
									}}
								>
									Create Expense
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
