import 'react-datepicker/dist/react-datepicker.min.css';

import './styles/base.scss';

// Initialize the braze
import './utils/brazeUtil';

import { AppRouter } from './routers';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import { Helmet } from 'react-helmet';
import { Toaster } from 'sonner';
import { Warning } from './components/icons';

function App() {
	// const methodDoesNotExist = () => {
	// 	throw new Error('This is sentry test error');
	// };
	const navigate = useNavigate();
	useEffect(() => {
		if (Number(process.env.REACT_APP_IS_APP_UNDER_MAINTENANCE || '') == 1) {
			navigate('/maintenance');
		}
	}, [navigate]);

	return (
		<>
			<Helmet>
				<title>InComm Benefits</title>
			</Helmet>
			{/* <button onClick={() => methodDoesNotExist()}>Break the world</button>; */}
			<Toaster
				position={'bottom-center'}
				icons={{
					info: <Warning />
				}}
				toastOptions={{
					className: 'text-base-325 react-sonner-custom'
				}}
			/>
			<Provider store={store}>
				<AppRouter />
			</Provider>
		</>
	);
}

export default App;
