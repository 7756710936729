import { APIService } from './apiService';

const PROFILE_DETAIL = '/v2/user/profile';
const UPDATE_ADDRESS_DETAIL = '/v2/user/profile/address';
const UPDATE_EMAIL_DETAIL = '/v2/user/profile/update/email';

const VALIDATE_PASSCODE = '/v2/user/profile/validate/email/passcode';

const RESEND_PASSCODE = '/v2/user/profile/validate/resend/passcode';
const UPDATE_PHONE_NUMBER = '/v2/user/profile/phone-number';

const CONTACT_US = '/v2/user/support/contactus';
const NOTIFY_USER = '/v2/user/notify';

const MFA_DETAIL = '/v2/user/mfa';

export const contactApi = {
	contactInformation(headers = {}) {
		return APIService.get(PROFILE_DETAIL, {}, headers);
	},
	updateAddress(address: any, headers = {}) {
		return APIService.patch(UPDATE_ADDRESS_DETAIL, address, {}, headers);
	},
	updateEmail(emailData: any) {
		return APIService.post(UPDATE_EMAIL_DETAIL, emailData);
	},
	validatePasscode(data: any) {
		return APIService.post(VALIDATE_PASSCODE, data);
	},
	resendPasscode(data: any) {
		return APIService.patch(RESEND_PASSCODE, data);
	},
	updatePhoneNumber(data: any) {
		return APIService.patch(UPDATE_PHONE_NUMBER, data);
	},
	contactUs(data: { message: string }) {
		return APIService.post(CONTACT_US, { ...data, support_type: 'generic' });
	},
	notifyUser(type: string) {
		return APIService.get(`${NOTIFY_USER}/${type}`);
	},
	getMFA() {
		return APIService.get(`${MFA_DETAIL}`);
	},
	setMFA(status: string) {
		return APIService.post(`${MFA_DETAIL}/${status}`, {});
	}
};
