export const numberWithCommas = (amount: number | string) => {
	return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getDecimalVal = (originalVal: number, decimal?: number) => {
	const convertedVal = decimal
		? Math.round(Number(originalVal || 0) * Math.pow(10, decimal)) / Math.pow(10, decimal)
		: (Math.round(Number(originalVal || 0) * 100) / 100).toFixed(2);
	return numberWithCommas(convertedVal);
};
