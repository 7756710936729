export const getControlStyle = (styles: any, state: any, variant: TSelectVariant) => {
	if (variant === 'SECONDARY') {
		return {
			...styles,
			border: '1px solid #001B47',
			borderRadius: '64px',
			padding: '7px 16px',
			minHeight: 'none',
			div: {
				padding: '0px'
			},
			outline: state.isFocused ? 'auto !important' : 'none',
			outlineColor: state.isFocused ? '#008FEF !important' : 'transparent',
			outlineOffset: state.isFocused ? '4px' : '0px',
			gap: '8px',
			// font styles
			fontFamily: "'Gotham', 'sans-serif'",
			fontSize: '16px',
			fontWeight: '350',
			lineHeight: '18px',
			letterSpacing: '0px',
			boxShadow: 'none'
		};
	}
	return {
		...styles,
		borderColor: '#D1D5DB',
		borderRadius: '8px',
		padding: '3px 4px',
		boxShadow: 'none',
		outline: state.isFocused ? 'auto !important' : 'none',
		outlineColor: state.isFocused ? '#455D8D !important' : 'transparent',
		outlineOffset: '0px',
		':focus': {
			boxShadow: 'none',
			borderColor: '#4e5154'
		},
		// font styles
		fontFamily: "'Gotham-Book', sans-serif",
		fontSize: '16px',
		fontWeight: '325',
		lineHeight: '18px',
		letterSpacing: '0px'
	};
};

export const getSingleValueStyle = (styles: any) => {
	return {
		...styles,
		'@media (max-width: 768px)': {
			overflowWrap: 'break-word',
			whiteSpace: 'normal',
			overflow: 'wrap',
			padding: '4px 0px',
			div: {
				flexWrap: 'wrap',
				columnGap: '12px',
				rowGap: '2px',
				alignItems: 'center'
			}
		}
	};
};

export const getPlaceholderStyle = (styles: any) => {
	return {
		...styles,
		color: '#111827'
	};
};

export const getOptionStyle = (styles: any, state: any) => {
	return {
		...styles,
		padding: '13px 12px',
		color: state.isSelected ? '#111827' : '#374151',
		fontFamily: "'Gotham-Book', 'sans-serif'",
		fontSize: '16px',
		fontWeight: 325,
		lineHeight: '18px',
		letterSpacing: '0px',
		'-webkit-font-smoothing': 'antialiased',
		'-moz-osx-font-smoothing': 'grayscale',
		boxSizing: 'border-box',
		backgroundColor: state.isSelected ? '#68B1E2' : 'white',
		':hover': {
			backgroundColor: '#E6F5FF'
		},
		border: state.isFocused
			? '2px solid #008FEF'
			: state.isSelected
			? '2px solid #68B1E2'
			: '2px solid white'
	};
};

export const getIndicatorsContainerStyle = (styles: any, variant: TSelectVariant) => {
	if (variant === 'SECONDARY') {
		return {
			...styles,
			div: {
				color: '#001B47',
				padding: '0px'
			}
		};
	}
	return {
		...styles,
		div: {
			color: '#20437D',
			svg: {
				width: '16px',
				height: '16px'
			}
		}
	};
};
export const getIndicatorSeparatorStyle = (styles: any) => {
	return {
		...styles,
		display: 'none'
	};
};

export const getMenuStyle = (styles: any) => {
	return {
		...styles,
		'> div: first-child': {
			padding: '0px'
		}
	};
};

// DEFAULT(PRIMARY) - This variant is the one we use in the forms of the app.
// SECONDARY - This variant has some border radius with Blue-theme border and text
type TSelectVariant = 'DEFAULT' | 'SECONDARY';

export const getSelectStyles = (variant: TSelectVariant = 'DEFAULT') => {
	return {
		control: (styles: any, state: any) => getControlStyle(styles, state, variant),
		singleValue: (styles: any) => getSingleValueStyle(styles),
		placeholder: (styles: any) => getPlaceholderStyle(styles),
		option: (styles: any, state: any) => getOptionStyle(styles, state),
		indicatorsContainer: (styles: any) => getIndicatorsContainerStyle(styles, variant),
		indicatorSeparator: (styles: any) => getIndicatorSeparatorStyle(styles),
		menu: (styles: any) => getMenuStyle(styles)
	};
};
