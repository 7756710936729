/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';

import { customAxios } from '../interceptors/axios';

export const APIService = {
	get(resource: string, params: any = {}, headers = {}, config = {}) {
		return customAxios.get(resource, {
			params: { isTokenRequired: true, ...params },
			headers,
			...config
		});
	},

	post(resource: string, data: any, params: any = {}, headers = {}) {
		return customAxios.post(resource, data, {
			params: { isTokenRequired: true, ...params },
			headers
		});
	},

	put(resource: string, data: any, params: any = {}, headers = {}) {
		return customAxios.put(resource, data, {
			params: { isTokenRequired: true, ...params },
			headers
		});
	},

	delete(resource: string, data: any = {}, params: any = {}, headers = {}) {
		return customAxios.delete(resource, {
			data: data,
			params: { isTokenRequired: true, ...params },
			headers
		});
	},

	patch(resource: string, data: any = {}, params: any = {}, headers = {}) {
		return customAxios.patch(resource, data, {
			params: { isTokenRequired: true, ...params },
			headers
		});
	},

	/**
	 * Perform a custom Axios request.
	 *
	 * data is an object containing the following properties:
	 *  - method
	 *  - url
	 *  - data ... request payload
	 *  - auth (optional)
	 *    - username
	 *    - password
	 **/
	customRequest(data: any) {
		return axios(data);
	}
};
