// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
}
const SvgCheckCircle = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 25 24"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<g fill={props.color || `#111827`}>
			<path d="M10 16.333A.987.987 0 0 1 9.333 16l-4-4a1 1 0 0 1 1.334-1.333L9.96 13.96 21.333 2.667A1 1 0 0 1 22.667 4l-12 12a.987.987 0 0 1-.667.333Z" />
			<path d="M12 24a12 12 0 0 1-10.493-6.213A11.56 11.56 0 0 1 .08 13.24 12 12 0 0 1 6.213 1.493 11.56 11.56 0 0 1 10.76.067a11.814 11.814 0 0 1 4.733.453 1 1 0 1 1-.573 1.907 10.16 10.16 0 0 0-4-.374A9.907 9.907 0 0 0 7.133 3.24 10 10 0 0 0 4.2 5.693a9.894 9.894 0 0 0-2.187 7.347A9.907 9.907 0 0 0 3.2 16.827a9.999 9.999 0 0 0 2.453 2.933A9.893 9.893 0 0 0 13 21.947a9.906 9.906 0 0 0 3.787-1.187 10 10 0 0 0 2.933-2.453 9.894 9.894 0 0 0 2.187-7.347 1.051 1.051 0 1 1 2.093-.2 12 12 0 0 1-6.147 11.747A11.56 11.56 0 0 1 13.24 24H12Z" />
		</g>
	</svg>
);
export default SvgCheckCircle;
