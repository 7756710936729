// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
}
const SvgContributionReceipt = ({
	title,
	titleId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 32 32"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fill={props.color || `#111827`}
			fillRule="evenodd"
			d="M.002 5.546a4.083 4.083 0 0 1 3.93-4.213h19.394a4.083 4.083 0 0 1 3.932 4.213v4.113h-1.85V5.546a2.16 2.16 0 0 0-2.08-2.23H7.25c.399.676.609 1.446.61 2.23v20.133a2.17 2.17 0 0 0 .552 1.533 1.97 1.97 0 0 0 1.475.664 2.16 2.16 0 0 0 2.08-2.23v-1.87h13.446v-5.75h1.85v5.75h4.734v1.869a4.084 4.084 0 0 1-3.93 4.212H9.887a3.74 3.74 0 0 1-2.804-1.268 4.2 4.2 0 0 1-1.074-2.944V8.85H.005zm28.06 22.329a2.15 2.15 0 0 0 2.076-2.116H13.811a4.4 4.4 0 0 1-.596 2.116zM1.852 6.87h4.156V5.546a2.083 2.083 0 1 0-4.156 0zM27.3 16.635h-2.003v-1.788h-1.788v-2.005h1.788v-1.788H27.3v1.788h1.787v2.002H27.3zm-10.016-4.042 1.256.397c2.556.734 2.939 2.19 2.893 3.183A3.18 3.18 0 0 1 18.4 19.2v1.31h-1.657v-1.332a3.184 3.184 0 0 1-2.933-3.173h2.251a1.403 1.403 0 0 0 1.577 1.27c.918 0 1.53-.431 1.546-1.116.016-.475-.215-.888-1.27-1.194l-1.271-.352c-2.328-.735-2.725-2.067-2.725-3.031a3.16 3.16 0 0 1 2.825-3.062V7.442H18.4v1.079a3.085 3.085 0 0 1 2.712 3.138h-2.236c0-.72-.46-1.194-1.363-1.194-.78 0-1.362.429-1.362 1.056 0 .272.092.78 1.133 1.072"
			clipRule="evenodd"
		/>
	</svg>
);
export default SvgContributionReceipt;
