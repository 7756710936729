import React from 'react';

import classes from './Checkbox.module.scss';
import { CheckboxTypes, CheckboxSize } from './Checkbox.constants';
import { getClassNames } from '../../../utils/Css';

type CheckboxProps = {
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	checked: boolean;
	isDisabled?: boolean;
	type?: CheckboxTypes;
	size?: CheckboxSize;
	children?: React.ReactNode;
};
export const Checkbox = ({
	checked,
	isDisabled = false,
	children,
	onChange,
	size = CheckboxSize.LARGE,
	type = CheckboxTypes.SUCCESS
}: CheckboxProps) => {
	const classNames = getClassNames(
		classes.box,
		type == CheckboxTypes.PRIMARY ? classes.primary : classes.success,
		size == CheckboxSize.MEDIUM ? classes.medium : classes.large,
		checked && classes.checked,
		isDisabled && classes.disabled
	);

	const classNamesForLabel = getClassNames(
		classes.label,
		isDisabled && classes.disabled,
		'is-size-h4-r',
		'd-flex',
		'align-items-center'
	);

	return (
		<label className={classNamesForLabel}>
			<div>
				<input
					type="checkbox"
					disabled={isDisabled}
					checked={checked}
					onChange={onChange}
					className={classes['input-checkbox']}
				/>
				<span className={`${classNames} ${classes['checkbox-span']}`}></span>
			</div>
			<div>{children}</div>
		</label>
	);
};
